<template>
  <div>
    <!-- <div class="bread-crumbs" style="margin-bottom: 10px">
      首页 &gt; 组织机构
    </div> -->
    <div class="title">
      <h4>组织架构</h4>
    </div>
    <div class="txt txt1">
      <p></p>
      <p style="white-space: normal; line-height: 2em; text-align: justify">
        <span style="color: #c10000; font-size: 14px"
          ><strong
            ><strong style="color: #c10000; white-space: normal"
              >名誉主席<br /><span style="color: rgb(0, 0, 0)"></span></strong
            ><span style="white-space: normal"></span></strong
          ><span
            style="font-size: 14px; white-space: normal; color: rgb(0, 0, 0)"
            >李果珍<span style="white-space: normal">&nbsp;  &nbsp;  &nbsp;</span
            >刘玉清<span style="white-space: normal">&nbsp;  &nbsp;  &nbsp;</span
            >戴建平<span style="white-space: normal">&nbsp;  &nbsp;  &nbsp;</span
            >张云亭<span style="white-space: normal">&nbsp;  &nbsp;  &nbsp;</span
            >周纯武<span style="white-space: normal">&nbsp;  &nbsp;  &nbsp;</span
            >刘怀军<span style="white-space: normal">&nbsp;  &nbsp;  &nbsp;</span
            >崔进国</span
          ><span style="white-space: normal">&nbsp;</span
          ><strong
            ><span style="white-space: normal"><br /></span><br /></strong
        ></span>
      </p>
      <p style="white-space: normal; line-height: 2em; text-align: justify">
        <span style="color: #c10000; font-size: 14px"
          ><strong>大会主席</strong><br
        /></span>
      </p>
      <p style="white-space: normal; line-height: 2em; text-align: justify">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"
          >陈&emsp;敏<span style="white-space: normal"
            >&nbsp; &nbsp; &nbsp;</span
          >金征宇<span style="white-space: normal">&nbsp; &nbsp; &nbsp;</span
          >王振常<span style="white-space: normal">&nbsp; &nbsp; &nbsp;</span
          >洪&emsp;楠<span style="white-space: normal"
            >&nbsp; &nbsp; &nbsp;</span
          >于春水<span style="white-space: normal">&nbsp; &nbsp; &nbsp;</span
          >李&emsp;欣<span style="white-space: normal"
            >&nbsp; &nbsp; &nbsp;</span
          >时高峰<span style="white-space: normal">&nbsp; &nbsp; &nbsp;</span
          >耿左军</span
        >
      </p>
      <p style="white-space: normal"><br /></p>
      <p style="white-space: normal; line-height: 2em; text-align: justify">
        <span style="color: #c10000; font-size: 14px"
          ><strong>执行主席</strong><br
        /></span>
      </p>
      <p style="white-space: normal; line-height: 2em; text-align: justify">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"
          >于经瀛<span style="white-space: normal"
            >&nbsp; &nbsp; &nbsp;宋&emsp;伟<span style="white-space: normal"
              >&nbsp; &nbsp; &nbsp;</span
            ></span
          >鲜军舫<span style="white-space: normal"
            ><span style="white-space: normal">&nbsp; &nbsp; &nbsp;</span
            >马&emsp;林</span
          ></span
        >
      </p>
      <p style="white-space: normal"><br /></p>
      <p style="line-height: 2em; text-align: justify">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"></span>
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="color: rgb(0, 0, 0)"
          ><strong
            ><span style="color: rgb(0, 0, 0); font-size: 14px"></span></strong
        ></span>
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="color: #c10000"
          ><strong
            ><span style="font-size: 14px">大会学术委员会</span></strong
          ></span
        >
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="color: rgb(0, 0, 0); font-size: 14px"
          >委员（按姓氏笔画排序）</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >于经瀛&nbsp; &nbsp; &nbsp;于春水&nbsp; &nbsp; &nbsp;于&emsp;薇&nbsp;
          &nbsp; &nbsp;马&emsp;林&nbsp; &nbsp; &nbsp;马&emsp;军&nbsp; &nbsp;
          &nbsp;马&emsp;静&nbsp; &nbsp; &nbsp;马晓海&nbsp; &nbsp;
          &nbsp;马祥兴&nbsp; &nbsp; &nbsp;王&emsp;青&nbsp; &nbsp;
          &nbsp;王&emsp;健&nbsp; &nbsp; &nbsp;王小宜&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >王仁贵&nbsp; &nbsp; &nbsp;王&emsp;屹&nbsp; &nbsp; &nbsp;王怡宁&nbsp;
          &nbsp; &nbsp;王贵生&nbsp; &nbsp; &nbsp;王振常&nbsp; &nbsp;
          &nbsp;王培军&nbsp; &nbsp; &nbsp;王光彬&nbsp; &nbsp; &nbsp;王梅云&nbsp;
          &nbsp; &nbsp;王锡明&nbsp; &nbsp; &nbsp;方继良&nbsp; &nbsp;
          &nbsp;石建成&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >石建军&nbsp; &nbsp; &nbsp;龙莉玲&nbsp; &nbsp; &nbsp;卢再鸣&nbsp;
          &nbsp; &nbsp;卢光明&nbsp; &nbsp; &nbsp;卢&emsp;洁&nbsp; &nbsp;
          &nbsp;印&emsp;弘&nbsp; &nbsp; &nbsp;冯&emsp;逢&nbsp; &nbsp;
          &nbsp;冯晓源&nbsp; &nbsp; &nbsp;范国光&nbsp; &nbsp; &nbsp;师光永&nbsp;
          &nbsp; &nbsp;吕&emsp;滨&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >朱&emsp;力&nbsp; &nbsp; &nbsp;朱文珍&nbsp; &nbsp;
          &nbsp;朱&emsp;旭&nbsp; &nbsp; &nbsp;朱明旺&nbsp; &nbsp;
          &nbsp;任&emsp;华&nbsp; &nbsp; &nbsp;刘文亚&nbsp; &nbsp;
          &nbsp;刘士远&nbsp; &nbsp; &nbsp;刘长柱&nbsp; &nbsp; &nbsp;刘玉林&nbsp;
          &nbsp; &nbsp;刘怀军&nbsp; &nbsp; &nbsp;刘亚欧&nbsp; &nbsp;
          &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >刘兆玉&nbsp; &nbsp; &nbsp;刘岳峰&nbsp; &nbsp; &nbsp;刘挨师&nbsp;
          &nbsp; &nbsp;刘爱莲&nbsp; &nbsp; &nbsp;刘&emsp;敏&nbsp; &nbsp;
          &nbsp;刘再毅&nbsp; &nbsp; &nbsp;刘晶哲&nbsp; &nbsp; &nbsp;廖伟华&nbsp;
          &nbsp; &nbsp;齐志刚&nbsp; &nbsp; &nbsp;江新青&nbsp; &nbsp;
          &nbsp;安宁豫&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >孙&emsp;钢&nbsp; &nbsp; &nbsp;孙应实&nbsp; &nbsp; &nbsp;孙晓伟&nbsp;
          &nbsp; &nbsp;孙&emsp;烨&nbsp; &nbsp; &nbsp;严福华&nbsp; &nbsp;
          &nbsp;杜静波&nbsp; &nbsp; &nbsp;李&emsp;欣&nbsp; &nbsp;
          &nbsp;李&emsp;澄&nbsp; &nbsp; &nbsp;李子平&nbsp; &nbsp;
          &nbsp;李天然&nbsp; &nbsp; &nbsp;李世杰&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >李宏军&nbsp; &nbsp; &nbsp;李坤成&nbsp; &nbsp; &nbsp;李松柏&nbsp;
          &nbsp; &nbsp;李相生&nbsp; &nbsp; &nbsp;李绍林&nbsp; &nbsp;
          &nbsp;李晓光&nbsp; &nbsp; &nbsp;李葆青&nbsp; &nbsp; &nbsp;叶兆祥&nbsp;
          &nbsp; &nbsp;杨正汉&nbsp; &nbsp; &nbsp;吴&emsp;宁&nbsp; &nbsp;
          &nbsp;吴飞云&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >邱建星&nbsp; &nbsp; &nbsp;余永强&nbsp; &nbsp; &nbsp;沈&emsp;文&nbsp;
          &nbsp; &nbsp;宋&emsp;彬&nbsp; &nbsp; &nbsp;宋&emsp;伟&nbsp; &nbsp;
          &nbsp;宋法亮&nbsp; &nbsp; &nbsp;查云飞&nbsp; &nbsp;
          &nbsp;张&emsp;明&nbsp; &nbsp; &nbsp;张&emsp;敬&nbsp; &nbsp;
          &nbsp;张&emsp;辉&nbsp; &nbsp; &nbsp;张&emsp;勇&nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >张云亭&nbsp; &nbsp; &nbsp;张立苹&nbsp; &nbsp; &nbsp;张永海&nbsp;
          &nbsp; &nbsp;张伟国&nbsp; &nbsp; &nbsp;张红梅&nbsp; &nbsp;
          &nbsp;张&emsp;青&nbsp; &nbsp; &nbsp;张雪宁&nbsp; &nbsp;
          &nbsp;张敏鸣&nbsp; &nbsp; &nbsp;张晓琴&nbsp; &nbsp; &nbsp;张惠茅&nbsp;
          &nbsp; &nbsp;张&emsp;滨&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >陈&emsp;敏&nbsp; &nbsp; &nbsp;陈&emsp;雷&nbsp; &nbsp;
          &nbsp;陈&emsp;峰&nbsp; &nbsp; &nbsp;罗天友&nbsp; &nbsp;
          &nbsp;金&emsp;龙&nbsp; &nbsp; &nbsp;金征宇&nbsp; &nbsp;
          &nbsp;周&emsp;石&nbsp; &nbsp; &nbsp;周俊林&nbsp; &nbsp;
          &nbsp;郑传胜&nbsp; &nbsp; &nbsp;郑卓肇&nbsp; &nbsp; &nbsp;郑晓风&nbsp;
          &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >单&emsp;鸿&nbsp; &nbsp; &nbsp;郎&emsp;宁&nbsp; &nbsp;
          &nbsp;居胜红&nbsp; &nbsp; &nbsp;赵心明&nbsp; &nbsp; &nbsp;赵世华&nbsp;
          &nbsp; &nbsp;钟&emsp;心&nbsp; &nbsp; &nbsp;段&emsp;凯&nbsp; &nbsp;
          &nbsp;侯&emsp;阳&nbsp; &nbsp; &nbsp;侯代伦&nbsp; &nbsp;
          &nbsp;胡道予&nbsp; &nbsp; &nbsp;时高峰&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >施海彬&nbsp; &nbsp; &nbsp;姜慧杰&nbsp; &nbsp; &nbsp;娄&emsp;昕&nbsp;
          &nbsp; &nbsp;洪&emsp;楠&nbsp; &nbsp; &nbsp;贺&emsp;毅&nbsp; &nbsp;
          &nbsp;胡春洪&nbsp; &nbsp; &nbsp;秦乃姗&nbsp; &nbsp; &nbsp;袁小东&nbsp;
          &nbsp; &nbsp;袁建华&nbsp; &nbsp; &nbsp;袁新宇&nbsp; &nbsp;
          &nbsp;袁慧书&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >耿左军&nbsp; &nbsp; &nbsp;贾文霄&nbsp; &nbsp; &nbsp;许茂盛&nbsp;
          &nbsp; &nbsp;徐&emsp;克&nbsp; &nbsp; &nbsp;徐&emsp;冰&nbsp; &nbsp;
          &nbsp;徐国生&nbsp; &nbsp; &nbsp;徐&emsp;磊&nbsp; &nbsp;
          &nbsp;高连东&nbsp; &nbsp; &nbsp;郭佑民&nbsp; &nbsp; &nbsp;郭顺林&nbsp;
          &nbsp; &nbsp;郭&emsp;勇&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >郭启勇&nbsp; &nbsp; &nbsp;曹代荣&nbsp; &nbsp; &nbsp;龚启勇&nbsp;
          &nbsp; &nbsp;银&emsp;武&nbsp; &nbsp; &nbsp;梁长虹&nbsp; &nbsp;
          &nbsp;梁宇霆&nbsp; &nbsp; &nbsp;彭卫军&nbsp; &nbsp; &nbsp;彭如臣&nbsp;
          &nbsp; &nbsp;彭&emsp;芸&nbsp; &nbsp; &nbsp;彭&emsp;朋&nbsp; &nbsp;
          &nbsp;蒋&emsp;涛&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >韩&emsp;丹&nbsp; &nbsp; &nbsp;陶晓峰&nbsp; &nbsp; &nbsp;程英升&nbsp;
          &nbsp; &nbsp;程晓光&nbsp; &nbsp; &nbsp;程流泉&nbsp; &nbsp;
          &nbsp;程敬亮&nbsp; &nbsp; &nbsp;曾庆玉&nbsp; &nbsp; &nbsp;曾蒙苏&nbsp;
          &nbsp; &nbsp;曾献军&nbsp; &nbsp; &nbsp;温智勇&nbsp; &nbsp; &nbsp;谢汝明&nbsp; &nbsp; &nbsp;</span
        >
      </p>
      <p>
        <span style="font-size: 14px; color: #000000"
          >谢&emsp;晟&nbsp; &nbsp; &nbsp;满凤媛&nbsp; &nbsp; &nbsp;蔡成仕&nbsp;
          &nbsp; &nbsp;蔡剑鸣&nbsp; &nbsp; &nbsp;薛华丹&nbsp; &nbsp;
          &nbsp;霍健伟</span
        >
      </p>
      <p>
        <br />
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="font-size: 14px"></span>
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="color: #c10000"
          ><strong
            ><span style="font-size: 14px">大会组织委员会</span></strong
          ></span
        >
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"
          >委员（按姓氏笔画排序）</span
        >
      </p>
      <p>
        <span style="font-size: 14px"
          ><span style="color: #000000; font-size: 14px"
            >于经瀛<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >于<span style="font-size: 14px">&emsp;</span>薇<span
              style="font-size: 14px"
              >&nbsp; &nbsp; &nbsp;</span
            >马<span style="font-size: 14px">&emsp;</span>军&nbsp; &nbsp;
            &nbsp;马<span style="font-size: 14px">&emsp;</span>林&nbsp; &nbsp;
            &nbsp;马晓海&nbsp; &nbsp; &nbsp;王仁贵&nbsp; &nbsp; &nbsp;王<span
              style="font-size: 14px"
              >&emsp;</span
            >屹&nbsp; &nbsp; &nbsp;王怡宁&nbsp; &nbsp; &nbsp;王贵生&nbsp; &nbsp;
            &nbsp;方继良&nbsp; &nbsp; &nbsp;邓晓涛</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >石建成<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >石建军<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >卢<span style="font-size: 14px">&emsp;</span>洁&nbsp; &nbsp;
            &nbsp;代静文&nbsp; &nbsp; &nbsp;冯<span style="font-size: 14px"
              >&emsp;</span
            >逢&nbsp; &nbsp; &nbsp;师光永&nbsp; &nbsp; &nbsp;吕<span
              style="font-size: 14px"
              >&emsp;</span
            >滨&nbsp; &nbsp; &nbsp;朱<span style="font-size: 14px">&emsp;</span
            >旭&nbsp; &nbsp; &nbsp;任<span style="font-size: 14px">&emsp;</span
            >华&nbsp; &nbsp; &nbsp;刘长柱&nbsp; &nbsp; &nbsp;刘亚欧&nbsp; &nbsp;
            &nbsp;</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >刘岳峰<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >刘<span style="font-size: 14px">&emsp;</span>敏<span
              style="font-size: 14px"
              >&nbsp; &nbsp; &nbsp;</span
            >刘晶哲&nbsp; &nbsp; &nbsp;齐志刚&nbsp; &nbsp; &nbsp;安宁豫&nbsp;
            &nbsp; &nbsp;孙应实&nbsp; &nbsp; &nbsp;孙晓伟&nbsp; &nbsp;
            &nbsp;孙<span style="font-size: 14px">&emsp;</span>烨&nbsp; &nbsp;
            &nbsp;杜静波&nbsp; &nbsp; &nbsp;李天然&nbsp; &nbsp;
            &nbsp;李世杰</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >李宏军<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >李相生<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >李晓光&nbsp; &nbsp; &nbsp;李葆青&nbsp; &nbsp; &nbsp;杨正汉&nbsp;
            &nbsp; &nbsp;吴<span style="font-size: 14px">&emsp;</span>宁&nbsp;
            &nbsp; &nbsp;邱建星&nbsp; &nbsp; &nbsp;谷<span
              style="font-size: 14px"
              ><span style="font-size: 14px">&emsp;</span></span
            >涛&nbsp; &nbsp; &nbsp;宋<span style="font-size: 14px"
              ><span style="font-size: 14px">&emsp;</span></span
            >伟&nbsp; &nbsp; &nbsp;张立苹&nbsp; &nbsp; &nbsp;张红梅</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >张&emsp;青<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >张<span style="font-size: 14px">&emsp;</span>滨<span
              style="font-size: 14px"
              >&nbsp; &nbsp; &nbsp;</span
            >陈<span style="font-size: 14px">&emsp;</span>敏&nbsp; &nbsp;
            &nbsp;陈<span style="font-size: 14px">&emsp;</span>雷&nbsp; &nbsp;
            &nbsp;金<span style="font-size: 14px">&emsp;</span>龙&nbsp; &nbsp;
            &nbsp;郑卓肇&nbsp; &nbsp; &nbsp;郑晓风&nbsp; &nbsp; &nbsp;郎<span
              style="font-size: 14px"
              >&emsp;</span
            >宁&nbsp; &nbsp; &nbsp;赵心明&nbsp; &nbsp; &nbsp;赵世华&nbsp; &nbsp;
            &nbsp;钟<span style="font-size: 14px">&emsp;</span>心</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >段&emsp;凯<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >侯代伦<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >娄<span style="font-size: 14px">&emsp;</span>昕&nbsp; &nbsp;
            &nbsp;洪<span style="font-size: 14px">&emsp;</span>楠<span
              style="font-size: 14px"
              >&nbsp; &nbsp; &nbsp;</span
            ><span style="color: #000000"></span>贺<span style="font-size: 14px"
              >&emsp;</span
            >毅&nbsp; &nbsp; &nbsp;秦乃姗&nbsp; &nbsp; &nbsp;袁小东&nbsp; &nbsp;
            &nbsp;袁新宇&nbsp; &nbsp; &nbsp;袁慧书&nbsp; &nbsp;
            &nbsp;徐文睿&nbsp; &nbsp; &nbsp;徐<span style="font-size: 14px"
              >&emsp;</span
            >冰</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >徐国生<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >徐<span style="font-size: 14px">&emsp;</span>磊<span
              style="font-size: 14px"
              >&nbsp; &nbsp; &nbsp;</span
            >高连东&nbsp; &nbsp; &nbsp;郭<span style="font-size: 14px"
              >&emsp;</span
            >勇&nbsp; &nbsp; &nbsp;梁宇霆&nbsp; &nbsp; &nbsp;彭如臣&nbsp; &nbsp;
            &nbsp;彭<span style="font-size: 14px"
              ><span style="font-size: 14px">&emsp;</span></span
            >芸&nbsp; &nbsp; &nbsp;彭<span style="font-size: 14px">&emsp;</span
            >朋&nbsp; &nbsp; &nbsp;蒋<span style="font-size: 14px">&emsp;</span
            >涛&nbsp; &nbsp; &nbsp;程晓光&nbsp; &nbsp; &nbsp;程流泉</span
          ><br />
          <span style="color: #000000; font-size: 14px"
            >曾庆玉&nbsp; &nbsp; &nbsp;温智勇<span style="font-size: 14px"
              ><span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span></span
            >谢汝明<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >谢<span style="font-size: 14px">&emsp;</span>晟<span
              style="font-size: 14px"
              >&nbsp; &nbsp; &nbsp;</span
            >满凤媛<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >蔡成仕<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >蔡剑鸣<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >鲜军舫<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >薛华丹<span style="font-size: 14px">&nbsp; &nbsp; &nbsp;</span
            >霍健伟</span
          ><br />
        </span>
      </p>
      <p>
        <span style="font-size: 14px"></span
        ><span style="font-size: 14px"></span
        ><span style="font-size: 14px"></span>
      </p>
      <p>
        <br />
      </p>
      <p style="line-height: 2em; text-align: justify">
        <span style="font-size: 14px; color: rgb(0, 0, 0)"></span>
      </p>
      <p style="line-height: 2em; text-align: justify; font-size: 14px">
        <strong style="color: #c10000">大会秘书</strong>
      </p>
      <p
        style="
          white-space: normal;
          font-size: 14px;
          line-height: 2em;
          text-align: justify;
        "
      >
        <span style="color: rgb(0, 0, 0)"
          >邓晓涛<span style="white-space: normal">&nbsp; &nbsp; &nbsp;</span
          >谷&emsp;涛<span style="white-space: normal"
            >&nbsp; &nbsp; &nbsp;</span
          >徐文睿<span style="white-space: normal">&nbsp; &nbsp; &nbsp;</span
          >代静文</span
        >
      </p>
      <p style="white-space: normal"><br /></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Organization",
};
</script>

<style scoped>
.title {
  border-bottom: 1px solid #e5f3fc;
  padding: 18px;
  margin-top: 22px;
  position: relative;
}
.title > h4 {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 1.4em;
  padding-left: 24px;
  position: relative;
  margin: 0;
}
.title > h4:after {
  content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background-color: #c10000;
}
p {
  margin: 0;
}
.txt {
  font-size: 16px;
  color: #797979;
  line-height: 2em;
  padding: 25px 30px;
}
</style>